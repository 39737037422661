import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import BrandLogo from '../assets/banner.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Header = () => (
  <Navbar id="navbar" collapseOnSelect expand="md" variant="light">
    <Container>
      <Navbar.Brand href="/" aria-label="Il logo di GN Informatica">
        <BrandLogo />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="justify-content-end"
      >
        <Nav>
          <Nav.Link className="link" href="/servizi">
            <FontAwesomeIcon icon="briefcase" /> Servizi
          </Nav.Link>
          <Nav.Link className="link" href="/contattaci">
            <FontAwesomeIcon icon="phone" /> Contattaci
          </Nav.Link>
          <Nav.Link
            className="link"
            href={'https://get.teamviewer.com/gninformatica'}
          >
            <FontAwesomeIcon icon="headset" /> Assistenza remota
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

export default Header
