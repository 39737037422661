import React from 'react'
import { PropTypes } from 'prop-types'

import Navbar from './navbar'

function Layout({ children }) {
  return (
    <div className="d-flex flex-column" style={{ minHeight: '100vh' }}>
      <Navbar />
      <div
        className="flex-grow-1 container-fluid"
        style={{
          padding: 0,
          margin: '0 auto',
          lineBreak: '1.6',
        }}
      >
        {children}
      </div>

      <footer className="footer ">
        <span className="block">P.IVA 04126380163</span>
        <span className="hidden"> - </span>
        <span className="block">C.F. NSPGPP73B22I628G</span>
        <span className="hidden"> - </span>Copyright GN Informatica. All rights
        reserved.
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
