import React from 'react'

export const PageTitle = ({ children }) => (
  <div
    style={{
      margin: '2rem 0 3rem',
    }}
  >
    <h1>{children}</h1>
  </div>
)

export default PageTitle
